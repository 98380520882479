import React from 'react';
import ReactDOM from "react-dom";
import {BrowserRouter , Route , Link , NavLink , Switch , Redirect} from "react-router-dom";

import axios from 'axios';

import FlexContainer from 'react-styled-flexbox';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { borders } from '@material-ui/system';
import flexbox from '@material-ui/system';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import ReadeditBoard from './ReadeditBoard';

const box4Style = {
background: '#FAFAF5',
borderRadius: '16px',
margin: '5px',
width: '100%'
}

const titleStyle = {
fontWeight: 'bold',
color: '#6D5C1C',
fontSize: '18px',
textAlign: 'center'
}
const title1Style = {
fontWeight: 'bold',
fontSize: '16px'
}


function Members() {
  return (
    <div>
    <div style = {box4Style}>
      <p style = {titleStyle}> RPEA CHAPTER 031 OFFICERS  </p>
        <ButtonGroup fullWidth aria-label="Top Navigation Bar">
          <Button href="BoardChange.php?title=president&table=boardm">PRESIDENT</Button>
          <Button href="BoardChange.php?title=vice president&table=boardm">VICE PRESIDENT</Button>
          <Button href="BoardChange.php?title=board secretary&table=boardm">BOARD SECRETARY</Button>
          <Button href="BoardChange.php?title=general meeting secretary&table=boardm">GENERAL MEETING SECRETARY</Button>
          <Button href="BoardChange.php?title=treasurer&table=boardm">TREASURER</Button>
          <Button href="BoardChange.php?title=sergent at arms&table=boardm">SERGENT AT ARMS</Button>
        </ButtonGroup>
        <br />
    </div>

    <div style = {box4Style}>
      <p style = {titleStyle}> DIRECTORS / COMMITTEE CHAIRS  </p>
        <ButtonGroup fullWidth aria-label="Top Navigation Bar">
          <Button href="BoardChange.php?title=program&table=dirm">PROGRAM </Button>
          <Button href="BoardChange.php?title=legislation&table=dirm">LEGISLATION</Button>
          <Button href="BoardChange.php?title=benefits &ins.&table=dirm">BENEFITS & INS.</Button>
          <Button href="BoardChange.php?title=sunshine&table=dirm">SUNSHINE</Button>
          <Button href="BoardChange.php?title=historian&table=dirm">HISTORIAN</Button>
          <Button href="BoardChange.php?title=hospitality&table=dirm">HOSPITALITY</Button>
        </ButtonGroup>
        <ButtonGroup fullWidth aria-label="Top Navigation Bar">
          <Button href="BoardChange.php?title=information desk&table=dirm">INFORMATION DESK</Button>
          <Button href="BoardChange.php?title=membership&table=dirmm">MEMBERSHIP</Button>
          <Button href="BoardChange.php?title=newsletter editor&table=dirm">NEWSLETTER EDITOR</Button>
          <Button href="BoardChange.php?title=associate editor&table=dirm">ASSOCIATE EDITOR</Button>
          <Button href="BoardChange.php?title=webmaster&table=dirm">WEBMASTER</Button>
        </ButtonGroup>
    </div>
    </div>
)};

export default Members;
