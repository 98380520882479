import React , {useState , useEffect} from 'react';
import ReactDOM from "react-dom";

import axios from 'axios';

import FlexContainer from 'react-styled-flexbox';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { borders } from '@material-ui/system';
import flexbox from '@material-ui/system';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';


function MeetingLoad() {
  const [meeting , setMeeting] = useState([]);
  const [isLoading , setIsLoading] = useState(false);
  const [isError , setIsError] = useState(false);

  useEffect(() => {
    async function fetchBoard()  {
      setIsError(false);
      setIsLoading(true);
      try {
        const result = await axios.post("php/meeting_extract.php");
        setMeeting(result.data);
        } catch(error) {
            setIsError(true);
            console.log("YUK_MEETING");
            console.log(error);
        }
          setIsLoading(false);
       };
        fetchBoard();
      }, []);
  return (
      <React.Fragment>
       {isLoading ?(<div> Loading ....</div> ):(
        <React.Fragment>
        <Card>
          <CardHeader titleTypographyProps={{variant:'h4' }} style={{ textAlign: 'center' , color: 'red' , fontWeight: '900'}} title={meeting.mtitle} subheader={meeting.msubtitle}  />
          <div style={{ fontSize: '20px' , fontWeight: 'bold'}} dangerouslySetInnerHTML={{__html: meeting.mtext}} />
        </Card>
        </React.Fragment>
     )}
     </React.Fragment>

  );
  }

export default MeetingLoad;
