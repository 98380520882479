
import React from "react";
import ReactDOM from "react-dom";
import {HashRouter , Route , Link , NavLink , Switch , Redirect} from "react-router-dom";

import { render } from 'react-dom';
import RpeaMenu from './components/RpeaMenu';
import Footer from './components/Footer';
import Home from './components/Home';
import Members from './components/Members';

const mainStyle = {
width: '100%',
maxWidth: '1140px',
margin: 'auto'
}


const  App = () => {
  return(
  <div style={mainStyle}>
    <RpeaMenu />
    <Switch>
      <Route exact path = "/"  component={Home} />
      <Route exact path ="/Home"  component={Home} />
      <Route path='/Members' component={Members} />
    </Switch>
    <Footer />
  </div>
  );
}
render((
  <HashRouter basename="/">
    <App />
  </HashRouter>
) , document.getElementById("root"));
