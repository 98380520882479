import React from 'react';
import ReactDOM from "react-dom";
import FlexContainer from 'react-styled-flexbox';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { borders } from '@material-ui/system';
import flexbox from '@material-ui/system';
import {StyledButton} from './containers';

const emailStyle = {
textAlign: 'center' ,
fontSize: '14px'
}

const spanStyle = {
  fontFamily: 'Asset',
  color: '#6D5C1C' ,
  fontSize: '2em' ,
  textAlign: 'center',
  }

  const footStyle = {
  background: '#E7E5D0',
  position: 'sticky',
  textAlign: 'center',
  left: '0',
  bottom: '0',
  right: '0',
  }

function Footer() {
  return (
    <footer  style ={footStyle}>
    <div>
    Questions? Comments? E-mail to <a  href="email_form.php?emailto=webmaster@rpea-31santaclaraco.org"> Webmaster</a>
    </div>


Powered  by                   <img src="images/Interserver2.png" alt="InterServer" />
            <a  href="http://www.interserver.net" >www.interserver.net</a> <br />
    </footer>

    )};

export default Footer;
