import styled from 'styled-components';
import Button from '@material-ui/core/Button';

import {Link , NavLink} from 'react-router-dom';


export const StyledButton = styled(Button)`
 &&{
  font-size: 16px;
  font-family: asset;
  font-weight: 900;
  text-align: center;
  color: 'black';
  padding: 2px 10px 2px 10px;
  box-shadow: 4px 4px 2px grey;
  background: linear-gradient(to bottom, rgba(255,255,204,0.2) , rgba(255,255,190,1));
  &:focus,
  &:hover {
     background-color: green;
     color: 'red';
     cursor: pointer;
    }
  }
  `;

export const FooterContainer = styled.div`
  display: flex;
 height: 20px;
 flex-direction: column;
 text-align: center;
`;
