import React , {useState , useEffect} from 'react';
import ReactDOM from "react-dom";

import axios from 'axios';

import FlexContainer from 'react-styled-flexbox';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { borders } from '@material-ui/system';
import flexbox from '@material-ui/system';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

const titleStyle = {
fontWeight: 'bold',
color: '#6D5C1C',
fontSize: '18px'
}

const title1Style = {
fontWeight: 'bold',
fontSize: '16px'
}


function LoadBoard() {
  const [boardm , setBoardm] = useState([]);
  const [isLoading , setIsLoading] = useState(false);
  const [isError , setIsError] = useState(false);

  useEffect(() => {
      async function fetchBoard()  {
         setIsError(false);
         setIsLoading(true);
      try {
        const result = await axios.post("php/board_extract.php");
         setBoardm(result.data);
      } catch(error) {
          setIsError(true);
          console.log("YUK");
          console.log(error);
      }
        setIsLoading(false);
     };
      fetchBoard();
    }, []);



  return (
    <React.Fragment>
     {isLoading ?(<div> Loading ....</div> ):(
      <React.Fragment>
        <div style ={titleStyle}> RPEA CHAPTER31 OFFICERS </div>
        <br />
        {boardm.map((board, bid) =>
        <div key = {bid}>
          <span style={title1Style}> {board.title} </span><br />
          <span style= {{color: (board.name === "vacant") ? "red" : "black"}} > {board.name} </span><br />
        </div>
        )}
      </React.Fragment>
   )}
</React.Fragment>

);
}

export default LoadBoard;
