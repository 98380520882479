import React from 'react';
import ReactDOM from "react-dom";

import FlexContainer from 'react-styled-flexbox';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { borders } from '@material-ui/system';
import flexbox from '@material-ui/system';
import {StyledButton} from './containers';

import {Link} from 'react-router-dom';

const h1Style = {
letterSpacing: '1px',
fontWeight: '900',
marginTop: '-5px',
marginBottom: '-10px' ,
paddingTop: '-5px',
color: '#6D5C1C' ,
fontSize: '3vw' ,
textAlign: 'center',
};

const bgStyle = {
background: '#FFFFE6',
borderRadius: '16px',
display: 'flex',
justifyContent: 'flex-start',
paddingTop: '0px'

};

const spanStyle = {
  color: '#6D5C1C' ,
  fontSize: '2.3vw' ,
  textAlign: 'center',
  }

const btndStyle ={
  textAlign: 'center',
  marginLeft: '20%',
  marginRight: '20%',
  marginTop: '-10px',
}

function RpeaMenu() {
  return (
    <AppBar style ={bgStyle} position = "sticky" >
      <Toolbar>
        <img src="images/RPEALogo100.png" alt="RPEA Logo"  />
        <Typography style={{flex: 1 }}>
          <div style={h1Style}> Retired Public Employees' Association <br /> </div>
            <div style={spanStyle}>Chapter 031 Santa Clara County </div>
        </Typography>
        <img src="images/SantaClara80.png" alt="SC Logo"  />
      </Toolbar>
      <div style = {btndStyle}>
        <StyledButton href="members.html">MEMBERS ACCESS</StyledButton>

      </div>

      <br />
    </AppBar>
    )};

export default RpeaMenu;
