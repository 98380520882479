
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from 'react-router-dom';
import {Route , Link , NavLink , Switch , Redirect} from "react-router-dom";

import MainPaper from './MainPaper';

function Home() {
  return(
  <div>
    <MainPaper />
  </div>
  );
}
export default Home;
