import React , {useState , useEffect} from 'react';
import ReactDOM from "react-dom";

import axios from 'axios';

import FlexContainer from 'react-styled-flexbox';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { borders } from '@material-ui/system';
import flexbox from '@material-ui/system';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Link from '@material-ui/core/Link';

import LoadBoard from './LoadBoard';
import LoadDir from './LoadDir';
import MeetingLoad from './MeetingLoad';
import LoadCalendar from './LoadCalendar';
import GuestLoad from './GuestLoad';

const boxStyle = {
minHeight: '100vh',
paddingBottom: '30px' ,
display: 'flex',
flexDirection: 'row' ,
}

const box1Style = {
background: '#FAFAF5',
borderRadius: '16px',
margin: '5px',
width: '72%'
}

const box2Style = {
background: '#FAFAF5',
borderRadius: '16px',
textAlign: 'center',
margin: '5px',
width: '27%',
}

const box3Style = {
color: '#922B21',
fontWeight: 'bold',
fontSize:  '22px',
padding: '0',
marginLeft: '40px',
align: 'center'
}

const titleStyle = {
fontWeight: 'bold',
color: '#6D5C1C',
fontSize: '18px'
}

const title1Style = {
fontWeight: 'bold',
fontSize: '16px'
}

const chStyle = {
fontWeight : 'bold',
fontSize: '18px'
}

const statStyle = {
fontWeight: 'bold',
fontSize: '20px',
textAlign: 'center'
}

const bottomStyle = {
position: 'sticky',
bottom: '0',
textAlign: 'center' ,
border: '1px solid green'
}

function MainPaper() {
  return (
  <div style= {boxStyle}>
    <div style = {box2Style}>
      <br />
      <LoadCalendar />
      <Divider />
      <LoadBoard />
      <Divider />
      <LoadDir />
      <Divider />
    </div>

    <div  style ={box1Style}>
      <br />
      <div style ={statStyle}>
        RPEA is a non-profit association of retirees and active employees who are members
          of the California Public Retirement System (CalPERS). RPEA represents all public employees , classified schools , public agencies and state. We are
          dedicated to maintaining the quality of the lives of members by protecting and improving their CalPERS retirement pensions and medical health benefits.
      </div>
      <br />
      <Divider />
      <br />
      <MeetingLoad />
     <Divider />
     <br />
     <GuestLoad />
     <Divider />
    <Card>
    <CardHeader titleTypographyProps={{variant:'h4' }} style={{ textAlign: 'center' , color: '#6D5C1C' }} title="IMPORTANT DOCUMENTS"  />
    <CardContent style={{ textAlign: 'center' , fontSize: '20px' , fontWeight: 'bold' , letterSpacing: '0.1em'}} >
      <Link href="/documents/RPEA Membership Form.pdf"> RPEA Membership Form </Link> <br />
      <Link href="/documents/Bylaws-chapter031.pdf" > Bylaws for Chapter 031 </Link> <br />
      <Link href="/documents/vested-rights_PERS.pdf"> CalPERS Vested Rights </Link> <br />
    </CardContent>
    </Card>
 <Divider />
  <Card>
  <CardHeader titleTypographyProps={{variant:'h5' }} style={{ textAlign: 'center' , color: '#6D5C1C' }} title="RETIREE ORGANIZATION LINKS"  />
    <CardContent style={{ textAlign: 'center' , fontSize: '20px' , fontWeight: 'bold' , letterSpacing: '0.1em'}} >
      <Link href="http://www.leginfo.ca.gov">California Legislative Info </Link> <br />
      <Link href="http://www.calpers.ca.gov"> CalPERS </Link> <br />
      <Link href="http://sccgov.org"> County of Santa Clara</Link> <br />
      <Link href="http://govtrack.us"> House of Representatives</Link> <br />
      <Link href="http://www.medicare.gov"> Medicare </Link> <br />
      <Link href="http://www.rpea.com">RPEA State Website</Link> <br />
      <Link href="http://www.senate.gov/legislative/bills_acts_laws.htm">Senate Bills</Link> <br />
      <Link href="http://www.vta.org">Valley Transport Authority</Link> <br />
    </CardContent>
  </Card>
  <Divider />
  <br />
    
      <Divider />
      <br /> <br />
    </div>
</div>

);
}

export default MainPaper;
